import * as React from "react";

const SVGRsBgPoly1 = (props) => (
	<svg
		width={192}
		height={326}
		viewBox="0 0 192 326"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M93.2089 0.0455015C96.7816 0.0455009 100.083 1.95149 101.869 5.0455L190.141 157.937C191.928 161.031 191.927 164.843 190.141 167.937L101.869 320.829C100.083 323.923 96.7816 325.829 93.2089 325.829L-83.3351 325.829C-86.9077 325.829 -90.209 323.923 -91.9954 320.829L-180.267 167.937C-182.054 164.843 -182.054 161.031 -180.267 157.937L-91.9953 5.0455C-90.209 1.95149 -86.9077 0.0454984 -83.3351 0.0455025L93.2089 0.0455015Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGRsBgPoly1;
