import * as React from "react";

const SVGRsBgPoly2 = (props) => (
	<svg
		width={250}
		height={393}
		viewBox="0 0 250 393"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M331.927 0.994243C335.499 0.994242 338.8 2.90023 340.587 5.99424L447.808 191.706C449.594 194.8 449.594 198.612 447.808 201.706L340.587 387.418C338.8 390.512 335.499 392.418 331.927 392.418L117.485 392.418C113.912 392.418 110.611 390.512 108.825 387.418L1.60408 201.706C-0.182253 198.612 -0.182258 194.8 1.60407 191.706L108.825 5.99424C110.611 2.90023 113.912 0.994238 117.485 0.994241L331.927 0.994243Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGRsBgPoly2;
