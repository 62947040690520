import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Link from "../../components/link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import WhatWeDoHero from "../../components/what-we-do-hero";
import PhotoLeft from "../../components/photo-left";
import PhotoRight from "../../components/photo-right";
import BeeBanner from "../../components/bee-banner";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GoPrimitiveDot } from "react-icons/go";
import SVGRsBgPoly1 from "../../components/SVG/rs-bg-poly-1";
import SVGRsBgPoly2 from "../../components/SVG/rs-bg-poly-2";

const RecruitmentAndSoftware = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Blog-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			RFGImg: wpMediaItem(title: { eq: "RFG-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			hirefulImg: wpMediaItem(title: { eq: "Hireful-Logo" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const RFGImg = data.RFGImg?.localFile.childImageSharp.gatsbyImageData;
	const hirefulImg = data.hirefulImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Recruitment And Software",
				item: {
					url: `${siteUrl}/what-we-do/recruitment-and-software`,
					id: `${siteUrl}/what-we-do/recruitment-and-software`,
				},
			},
		],
	};
	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Recruitment And Software | Hive HR Solutions"
					description="You can benefit from having a dedicated member of our team managing your recruitment from start to finish to ensure they find the candidate that is the best fit for your business."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/what-we-do/recruitment-and-software`,
						title: "Recruitment And Software | Hive HR Solutions",
						description:
							"You can benefit from having a dedicated member of our team managing your recruitment from start to finish to ensure they find the candidate that is the best fit for your business.",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section>
					{" "}
					<WhatWeDoHero
						title="Recruitment and software"
						img={
							<Row>
								<Col>
									<GatsbyImage
										className="w-100 tImage"
										alt={data.heroImg?.altText}
										image={heroImg}
									/>
								</Col>
							</Row>
						}
					/>
				</section>
				<section>
					<PhotoRight
						height="37rem"
						width="25rem"
						ctaUrl="/hr-packages"
						cta="Learn more"
						mobileOff
						img={RFGImg}
						imgAlt={data.RFGImg?.altText}
						heading="Recruitment for growth"
						text={
							<span>
								<p>
									You can benefit from having a dedicated member of our team
									managing your recruitment from start to finish to ensure they
									find the candidate that is the best fit for your business.
								</p>
								<p>
									We offer a recruitment package that will save you time, this
									includes:
								</p>

								<ul className="help-list mt-4 ps-0">
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Create a job description and advert that outlines the role
											and candidate you are looking for
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Advise on where to advertise and salary level{" "}
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Use our Applicant Tracking System to screen candidates for
											you all in one place
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Conduct telephone screening so you only interview the best
											candidates
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Write effective interview questions
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Protect your reputation by managing all feedback and
											responding to every application
										</p>
									</li>
								</ul>
							</span>
						}
					/>
				</section>
				<section className="py-xl-10 position-relative">
					<SVGRsBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute start-0 top--20 d-none d-lg-block "
					/>
					<SVGRsBgPoly2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 bottom-10 d-none d-lg-block "
					/>{" "}
					<PhotoLeft
						idLeft="Recruitment-Left"
						idRight="Recruitment-Right"
						hideDots={true}
						img={hirefulImg}
						imgAlt={data.hirefulImg?.altText}
						heading="Recruitment software: Hireful"
						text={
							<span>
								<p>
									Do you dream of having all job applications in one place and
									not in your emails?
								</p>
								<p>
									Hireful provides an applicant tracking system where you can
									place adverts, add screening questions, view applications and
									manage feedback.
								</p>
								<p>
									You can even have your own branded career page providing you
									an opportunity to sell your company to candidates.
								</p>

								<p>
									Find out more about
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://hireful.co.uk"
										className="link-orange cta-link"
									>
										{" "}
										https://hireful.co.uk
									</Link>
								</p>
							</span>
						}
					/>
				</section>
				<section>
					<BeeBanner
						headline="Call today to get Hireful set up at your company"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default RecruitmentAndSoftware;
